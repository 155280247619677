import { Example } from "./Example";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "What are the key steps I need to follow to buy a house in New Zealand?",
    "What should I do if I encounter problems during the house buying process in NZ?",
    "Am I eligible to buy a house in New Zealand as a foreigner?"
];

const GPT4V_EXAMPLES: string[] = [
    "Can you identify any trends in house prices?",
    "How do interest rates affect the real estate market recently?",
    "What factors should I consider when investing in rental properties?"
];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
